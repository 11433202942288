<template>
    <form-view title="Смена вариантов работ и заданий">
        <switch-options-form />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import SwitchOptionsForm from '@/components/forms/SwitchOptionsForm/Index'
export default {
    components: { FormView, SwitchOptionsForm }
}
</script>
