<template>
    <div class="d-flex flex-column">
        <v-avatar
            :color="$const.color.primary"
            size="32"
            class="c-pointer"
            @click="$emit('click:item', value)"
        >
            <span class="white--text">{{ value }}</span>
        </v-avatar>

        <div class="d-flex align-center justify-space-between">
            <v-icon small title="Переместить назад" @click="$emit('click:left-arrow', value)">mdi-arrow-left</v-icon>
            <v-icon small title="Переместить вперед" @click="$emit('click:right-arrow', value)">mdi-arrow-right</v-icon>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: [String, Number], required: true }
    }
}
</script>