
<template>
    <v-progress-circular
        v-if="loading.initial"
        :size="50"
        :color="$const.color.primary"
        indeterminate
        class="d-flex mx-auto"
    ></v-progress-circular>
    <v-form v-else>
        <v-text-field
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Год"
            type="number"
            :disabled="loading.logs || loading.options"
            :color="$const.color.primary"
            @change="onFormParamsChanged"
            ></v-text-field>

        <v-select
            v-model="$v.form.subject.$model"
            :error-messages="getErrors('form.subject')"
            required
            :items="subjects"
            label="Предмет"
            :disabled="loading.logs || loading.options"
            @change="onFormParamsChanged"
        />

        <v-select
            v-model="$v.form.grade.$model"
            :disabled="!$v.form.subject.$model || loading.logs || loading.options"
            :error-messages="getErrors('form.grade')"
            required
            :items="grades"
            label="Класс"
            @change="onFormParamsChanged"
        />

        <v-divider class="my-4" />

        <v-progress-linear
            v-if="loading.logs || loading.options"
            :color="$const.color.primary"
            indeterminate
            class="d-flex"
        ></v-progress-linear>

        <v-alert
            v-if="rules !== null && isRulesEmpty"
            outlined
            text
            type="warning"
        >
            <p class="mb-0">Данные по указанному фильтру не найдены.</p>
        </v-alert>
        <v-alert
            v-else-if="rules"
            :color="$const.color.primary"
            outlined
            text
            type="info"
        >
            <p>Зеленым указаны изначальные значения вариантов. Числа над ними - это текущий вариант.</p>
            <p>Нажатие на стрелку поменяет местами текущий вариант с соседним.
                Нажатие на зеленый кружок вызовет окно, где можно указать с каким вариантом сделать замену.</p>
            <p class="mb-0">Для сохранения изменений необходимо нажать кнопку "Применить".
                Перед непосредственной заменой вариантов на сервере будет произведено сохранение дампов таблиц Работ и Заданий.
                По этому если операция завершилась с ошибкой или привела к неожиданному результату, есть возможность откатиться до предыдущего состояния.
                На сервере хранятся дампы состояния до крайней операции. Каждая последующая заменяет предыдущие дампы.
            </p>
        </v-alert>
        
        <div
            v-if="rules !== null && typeof rules === 'object'"
            class="d-flex flex-wrap mb-5"
        >
            <div
                v-for="(option, key) in rules"
                :key="key"
                class="d-flex flex-column align-center mr-2 mb-2"
            >
                <span>{{ key }}</span>
                <option-item
                    :value="option"
                    @click:item="onItemClick(key)"
                    @click:left-arrow="onArrowClick(key, -1)"
                    @click:right-arrow="onArrowClick(key, 1)"
                />
            </div>
        </div>

        <div class="d-flex align-center mt-2">

            <v-btn
                outlined
                :color="$const.color.primary"
                :disabled="!canRequest || isRulesEmpty"
                :loading="loading.submit"
                class="mr-5"
                @click="submit"
            >Применить</v-btn>

            <v-btn
                outlined
                :disabled="!canRequest || isRulesEmpty"
                x-small
                @click="exportToCSV"
            >Выгрузить лог изменений (csv)</v-btn>
        </div>

        <v-alert
          v-if="!_.isNil(error)"
          dense
          type="error"
          class="mt-5"
        >
            {{ error }}
        </v-alert>

        <v-alert
          v-if="!_.isNil(serverErrors)"
          dense
          type="error"
          class="mt-5"
        >
            {{ serverErrors }}
        </v-alert>
    </v-form>
</template>

<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import OptionItem from './OptionItem.vue'
import { saveDataAsFile } from '@/helpers/File'

export default {
    mixins: [errorMixin, saveMixin, validationMixin],
    components: { OptionItem },
    data () {
        return {
            loading: {
                initial: false,
                logs: false,
                options: false,
                submit: false
            },
            form: {
                year: (new Date()).getFullYear(),
                subject: '',
                grade: 0,
                rules: null
            },
            error: null,
            maxOption: null,
            initialRules: null,
            rules: null,
            log: null
        };
    },
    validations() {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required },
                rules: { required }
            }
        }
    },
    computed: {
        subjects () {
            return this.$store.state.app.subjects
        },
        grades () {
            if (!this.form.subject) { return [] }
            return this.subjects.find(item => item.value === this.form.subject)?.grades.flat()
        },
        canRequest () {
            return  this.form.subject &&
                    this.form.grade && parseInt(this.form.grade) > 0 &&
                    this.form.year && parseInt(this.form.year) > 0
        },
        isRulesEmpty () {
            return !this.rules || !Object.keys(this.rules)?.length
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }
    },
    methods: {
        async onFormParamsChanged () {

            await this.fetchMaxOption()
            await this.fetchRules()
        },
        async fetchMaxOption () {
            if (!this.canRequest || this.loading.options) return

            try {
            
               this.loading.options = true
                
               const filter = {
                    subject: this.form.subject,
                    grade: this.form.grade,
                    year: this.form.year,
                    collection: 1,
                    task: 1,
                    category: 'test'
               }
               const { success, data, error } = await this.$store.dispatch('task/list', { filter, fields: 'option', pagination: 0 })
            
               if (!success) throw new Error(error)

               this.maxOption = Math.max(...data.items.map((item) => item.option))
            
            } catch (e) {
            
                console.error(e)
                this.maxOption = null

            } finally {
            
               this.loading.options = false
            
            }
        },
        async fetchRules () {
            if (!this.canRequest || this.loading.logs) return

            try {
                this.error = null
                this.loading.logs = true
                this.rules = null
                    
                const filter = {
                        subject: this.form.subject,
                        grade: this.form.grade,
                        year: this.form.year    
                }
                const { success, data, error } = await this.$store.dispatch(
                    'option_changes_log/list',
                    { filter, fields: 'created_at,log', pagination: 0, sort: 'created_at' }
                )
            
                if (!success) throw new Error(error)
                
                this.log = data.items
                this.rules = this.processLogsToRules(this.log.map((item) => item.log))
                this.initialRules = window.structuredClone(this.rules)
            
            } catch (e) {
            
                console.error(e)
                this.error = e?.message

            } finally {
            
               this.loading.logs = false
            
            }
        },
        getInitialRules (maxOption) {

            const rules = {}

            for (let index = 1; index <= maxOption; index++)
                rules[index] = index

            return rules
        },
        processLogsToRules (logs) {

            if (!this.maxOption) throw new Error('Can not process rules without maxOption value.')

            const rules = this.getInitialRules(this.maxOption)
            for (const log of logs) {
                
                const logRules = JSON.parse(log)
                const rulesCopy = window.structuredClone(rules)

                for (const key in logRules)
                    rules[logRules[key]] = rulesCopy[key]
            }

            return rules
        },
        processRulesToFormRules () {

            const formRules = {}

            for (const key in this.initialRules) {

                if (this.initialRules[key] === this.rules[key]) { continue }

                formRules[key] = Object.keys(this.rules).find(k => this.rules[k] === this.initialRules[key])
            }

            return Object.keys(formRules).length === 0 ? null : JSON.stringify(formRules)
        },
        onItemClick (pickedOptionKey) {

            const promptText = `Укажите номер варианта с которым необходимо поменять выбранный вариант (${pickedOptionKey})`
            
            let nextKey = parseInt(prompt(promptText))

            if (!nextKey || nextKey > this.maxOption || nextKey < 1) {

                alert('Введенно некоректное значение.')
                return
            }

            nextKey = nextKey.toString()

            // Swap values
            const currentOptionValue = this.rules[pickedOptionKey]
            this.rules[pickedOptionKey] = this.rules[nextKey]
            this.rules[nextKey] = currentOptionValue
        },
        onArrowClick (pickedOptionKey, direction = 1) {

            let nextKey = parseInt(pickedOptionKey) + direction

            if (nextKey > this.maxOption)
                nextKey = 1

            if (nextKey <= 0)
                nextKey = this.maxOption

            nextKey = nextKey.toString()

            // Swap values
            const currentOptionValue = this.rules[pickedOptionKey]
            this.rules[pickedOptionKey] = this.rules[nextKey]
            this.rules[nextKey] = currentOptionValue
        },
        exportToCSV () {

            const csvContent = []
            const dateTimeFormat = 'YYYY-MM-DD HH:mm'

            for (let index = 0; index < this.log.length; index++) {
                
                const result = this.processLogsToRules(this.log.slice(0, index + 1)?.map((item) => item.log))

                const date = this.$moment.unix(this.log[index].created_at).format(dateTimeFormat)
                csvContent.push(['Дата изменений', date])
                csvContent.push(['Текущие варианты'], ['Исходные варианты'])

                for (const key in result) {

                    csvContent[csvContent.length - 2].push(key)
                    csvContent[csvContent.length - 1].push(result[key])

                }
                csvContent.push([], [])
            }

            const csvContentString = csvContent.map((row) => row.join(';')).join('\r')

            saveDataAsFile({ data: csvContentString, type: 'text/csv', name: `ОКО ВПР - Изменения вариантов ${this.form.year} ${this.form.subject} ${this.form.grade}.csv` })

        },
        async submit () {
            
            if (this.loading.submit) { return false; }

            const confirmationText = 'Вы уверены, что хотите применить указанные замены вариантов?'

            if (!confirm(confirmationText)) return

            this.error = null

            this.form.rules = this.processRulesToFormRules()

            if (!this.validate()) {
                console.error('Validation failed');
                this.error = 'Форма заполнена не правильно'
                return false;
            }
            
            try {
                this.loading.submit = true

                const { success, error } = await this.$store.dispatch('app/switchOptions', this.form)

                if (!success) throw new Error(error)

                alert('Процедура успешно завершена')

                this.fetchRules()
                
            } catch (e) {
                console.error(e)
                this.error = e?.message || 'Неизвестная ошибка обработки'
            }
            finally {
                this.loading.submit = false
                this.form.rules = null
                this.$emit('success', 1);
            }
        }
    }
}
</script>